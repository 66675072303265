import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import logo from "../../assets/img/logotwo.png";
// Assets
import LogoImg from "../../assets/svg/Logo";

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper
            className="flexSpaceCenter"
            style={{ padding: "30px 0" }}
          >
            <Link
              className="flexCenter animate pointer"
              to="home"
              smooth={true}
              offset={-80}
            >
              <Img
                className="radius8"
                src={logo}
                alt="office"
                style={{ zIndex: 9 }}
              />
              <h1 style={{ marginLeft: "15px" }} className="font20 extraBold">
                {/* fanatic */}
              </h1>
            </Link>

            <StyleP className="whiteColor font13">
              © {getCurrentYear()} -{" "}
              <span className="redColor font13">TravelKook</span> All Right
              Reserved
            </StyleP>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;
const Img = styled.img`
  width: 150px;
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
